import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const Cookies = props => (
  <Layout {...props}>
    <Seo title='Politique des Cookies' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header='Politique des Cookies'
            subheader='Dernier mise à jour : 19 septembre, 2022'
          />
          <h3 id='cookies'>Cookies</h3>
          <p>Voici la liste des cookies utilisées et leur objectif :</p>
          <ul>
            <li>
              Cookies Google Analytics (
              <a href='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage'>
                liste exhaustive
              </a>
              ) : Web analytics
            </li>
            <li>
              Permet de garder en mémoire le fait que vous acceptez les cookies
              afin de ne plus vous importuner lors de votre prochaine visite.
            </li>
          </ul>
          <h3 id='vos-droits-concernant-les-données-personnelles'>
            Vos droits concernant les données personnelles
          </h3>
          <p>
            Vous avez le droit de consultation, demande de modification ou
            d’effacement sur l’ensemble de vos données personnelles. Vous pouvez
            également retirer votre consentement au traitement de vos données.
          </p>

          <script
            data-cfasync='false'
            src='/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js'
          ></script>
        </Section>
        <Section>
          <Button variant='primary' as={Link} to='/'>
            Page d'accueil
          </Button>
        </Section>
      </Main>
    </Stack>
  </Layout>
)

export default Cookies
